@font-face {
  font-family: 'IIIncrementsSans';
  src: url('./fonts/IIIncrementsSansBlack.otf') format('opentype');
}

@font-face {
  font-family: 'IIIncrementsSansMedium';
  src: url('./fonts/IIIncrementsSansMedium.otf') format('opentype');
}

.App {
  height: 100vh; /* Double the height */
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevent scrollbars on .App */
  position: relative;
  background-color: white;
}

canvas {
  position: absolute; /* Keep canvas in place */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none; /* Ignore interactions */
  z-index: 0; /* Ensure it stays in the background */
  background-color: transparent; /* Make canvas background transparent */
}

.name-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'IIIncrementsSans', sans-serif;
  font-size: 1.5rem;
  color: white;
  mix-blend-mode: difference;
  pointer-events: none;
  background-color: black;
  z-index: 0; /* Ensure it stays above the canvas */
}

body, html {
  margin: 0;
  padding: 0;
  font-family: 'IIIncrementsSansMedium', sans-serif;
  height: 100%;
  overflow-y: scroll; /* Enable scrolling on the body */
  overflow-x: hidden; /* Prevent horizontal overflow */
  cursor: none;
}

html {
  scroll-behavior: smooth;
}

:root {
  --spacing-gap: 2rem;
}

.nav-bar {
  position: fixed; /* Keep nav bar fixed while scrolling */
  top: 3%;
  right: 3%;
  display: flex;
  gap: var(--spacing-gap);
  padding: 1rem;
  z-index: 2; /* Ensure it stays above the canvas */
}

.nav-bar a {
  text-decoration: none;
  color: black;
  font-size: 0.8rem;
}

.footer {
  position: fixed; /* Keep footer fixed while scrolling */
  bottom: 3%;
  left: 3%;
  display: flex;
  gap: var(--spacing-gap);
  padding: 1rem;
  z-index: 2; /* Ensure it stays above the canvas */
}

.footer a {
  text-decoration: none;
  color: black;
  font-size: 0.8rem;
}

.custom-cursor {
  width: 15px;
  height: 15px;
  border: 1px solid black;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
}



/* Mobile */
@media (max-width: 768px) {
  .name-overlay {
    font-size: 1.25rem; /* Adjust font size for smaller screens */
  }

  .nav-bar,
  .footer {
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    left: 50%; /* Start centering position */
    transform: translateX(-50%); /* Adjust centering */
    width: 100%; /* Ensure they span full width */
    gap: 2rem; /* Adjust gap between elements if necessary */
  }
}