.custom-cursor {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    pointer-events: none;
    background: white;
    mix-blend-mode: difference;
    border: 1px solid black;
    opacity: 0;
    transition: opacity 0.5s ease-in;
    position: absolute;
    z-index: 2;
}

@keyframes increaseSize {
    from {
        width: 15px;
        height: 15px;
    }
    to {
        width: 20px;
        height: 20px;
    }
}

@keyframes decreaseSize {
    from {
        width: 20px;
        height: 20px;
    }
    to {
        width: 15px;
        height: 15px;
    }
}


.custom-cursor.hover {
    animation: increaseSize 0.3s forwards;
}

.custom-cursor.no-hover {
    animation: decreaseSize 0.3s forwards;
}
