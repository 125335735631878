/* Loading Screeen */

#loadingScreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 1;
  }
  
  .tile {
    background-color: black;
    width: 100%; /* Width of each tile */
    height: 100%; /* Full height initially */
    z-index: 1;
  }
  
  @keyframes slideUp {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100%);
    }
  }
  
  @keyframes slideDown {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(100%);
    }
  }
  
  .drop-top {
    animation: slideUp 1s forwards ease-in-out; /* Slide up animation */
  }
  
  .drop-bottom {
    animation: slideDown 1s forwards ease-in-out; /* Slide down animation */
  }  